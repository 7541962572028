.chatbox {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 300px;
}

.chatbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.model-selector {
  padding: 5px;
}

.chatbox-messages {
  flex: 1;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}

.message {
  padding: 4px 8px;
  margin: 4px 0;
}

.message.user {
  text-align: right;
  background-color: #e0f7fa;
  border-radius: 4px;
}

.message.bot {
  text-align: left;
  background-color: #f1f8e9;
  border-radius: 4px;
}

.chatbox-input {
  display: flex;
  justify-content: center;
}
