/* App.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1b1b1b;
  color: white;
}

.App {
  text-align: center;
}

.navbar {
  background-color: #333;
  padding: 1rem;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-logo {
  color: #ffffff;
  font-size: 1.5rem;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.navbar-logo-img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.navbar-link {
  color: #ffffff;
  text-decoration: none;
}

.navbar-link:hover {
  text-decoration: underline;
}

.features-container, .pricing-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #1a1a1a;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.features-container h2, .pricing-container h2 {
  color: #ff7e5f;
  font-size: 2rem;
  margin-bottom: 10px;
}

.features-container p, .pricing-container p {
  font-size: 1.2rem;
  color: #e0e0e0;
}
